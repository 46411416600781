/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import LayoutWithAuth from '../components/layoutWithAuth';
import PageTransition from '../components/PageTransition/index';
import { Box } from '../style/basicStyle';
import colors from '../utils/colors';

const downloadLink = css`
  font-size: 28px;
  text-decoration: underline;
  font-weight: bold;
`;
const disabled = css`
  pointer-events: none;
  color: gray;
`;

const teamDocuments = ({ toggleNavDark, location }) => (
  <PageTransition location={location}>
    <LayoutWithAuth navDark toggleNavDark={toggleNavDark}>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Team Documents</title>
      </Helmet>
      <Box bg={colors.primary}>
        <Box fontSize={[2]} my={[2]} bg={colors.primary}>
          <Box width={[1, 1, 1 / 2]} m={['3.5rem 0 0 0', '3.5rem 0 0 0', '3.5rem auto 0 auto']} px={[3, 3, 0]} color={colors.secondary}>
            <h1>Bulldogs Team Documents</h1>
            <h2>Below you will find links to download all team-related documents.</h2>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" href="/documents/2024/Check_List_24-25.rtf">
                Player Paperwork Checklist
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" href="/documents/2024/24-25_Contact_Form.docx">
                Player Contact Information
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/2024/consent-to-treat-national.pdf">
                Consent to Treat Form
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/2024/SAHOF_Concussion_Informed_Consent_Form_REV-2014.pdf">
                Player Concussion Form
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/2024/player_code_of_conduct_player_copy.docx">
                Player Code of Conduct
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/2024/USA_Hockey_Parent_Code_of_Conduct[5157].pdf">
                Bulldogs Parent Code of Conduct
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/2024/Bulldogs_Uniform_Standards_24-25.docx">
                Uniform Guidelines
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="/documents/coaches_code_of_conduct.rtf">
                Coaches Code of Conduct
              </a>
            </Box>
            <Box py={[3]}>
              <a css={downloadLink} target="_blank" rel="noreferrer" href="https://portal.usahockey.com/forms/usah-safesport-handbook.pdf">
                Safe Sport Hockey Handbook
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </LayoutWithAuth>
  </PageTransition>
);
export default teamDocuments;
